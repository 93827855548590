import { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./styles/global.css";

import Loading from "./components/Loading/Loading";
import PublicReservationRoutes from "./routes/PublicReservationRoutes";
import ProtectedReservationRoutes from "./routes/ProtectedReservationRoutes";
import PublicUserRoutes from "./routes/PublicUserRoutes";
import ProtectedUserRoutes from "./routes/ProtectedUserRoutes";
import WithStripeScript from "./routes/WithStripe";

// Policy Pages
const TermsConditions = lazy(
  () => import("./pages/Reservation/Policies/TermsConditions")
);

// User access routes
const UserLayout = lazy(
  () => import("./pages/Reservation/UserLayout/UserLayout")
);
const ReservationLogin = lazy(
  () => import("./pages/Reservation/Login/ReservationLogin")
);
const Reservation = lazy(
  () => import("./pages/Reservation/Reservation/Reservation")
);
const ReservationPayment = lazy(
  () => import("./pages/Reservation/Payment/Payment")
);
const ReservationConfirmation = lazy(
  () => import("./pages/Reservation/Confirmation/Confirmation")
);

// Admin access routes
const Login = lazy(() => import("./pages/HR/Login/Login"));
const SignUp = lazy(() => import("./pages/HR/SignUp/SignUp"));
const AdminLayout = lazy(() => import("./pages/HR/AdminLayout/AdminLayout"));
const AdminReservation = lazy(
  () => import("./pages/HR/Reservation/Reservation")
);
const PaySlip = lazy(() => import("./pages/HR/PaySlip/PaySlip"));
const Claims = lazy(() => import("./pages/HR/Claims/Claims"));
const Attendance = lazy(() => import("./pages/HR/Attendance/Attendance"));
const Dashboard = lazy(() => import("./pages/HR/BulletinBoard/ButtletinBoard"));
const Settings = lazy(() => import("./pages/HR/Settings/Settings"));
const Help = lazy(() => import("./pages/HR/Help/Help"));

export default function App() {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* User access routes */}
          <Route
            path="/"
            element={
              <PublicReservationRoutes>
                <WithStripeScript>
                  <ReservationLogin />
                </WithStripeScript>
              </PublicReservationRoutes>
            }
          />
          <Route
            path="/*"
            element={
              <WithStripeScript>
                <ProtectedReservationRoutes>
                  <UserLayout />
                </ProtectedReservationRoutes>
              </WithStripeScript>
            }
          >
            <Route path="reservation" element={<Reservation />} />
            <Route path="payment" element={<ReservationPayment />} />
            <Route path="confirmation" element={<ReservationConfirmation />} />
            <Route path="terms&conditions" element={<TermsConditions />} />
          </Route>

          {/* Admin access routes */}
          <Route
            path="/adm/login"
            element={
              <PublicUserRoutes>
                <Login />
              </PublicUserRoutes>
            }
          />
          <Route
            path="/adm/*"
            element={
              <ProtectedUserRoutes>
                <AdminLayout />
              </ProtectedUserRoutes>
            }
          >
            <Route path="add-user" element={<SignUp />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="reservation" element={<AdminReservation />} />
            <Route path="pay-slip" element={<PaySlip />} />
            <Route path="claims" element={<Claims />} />
            <Route path="attendance" element={<Attendance />} />
            <Route path="settings" element={<Settings />} />
            <Route path="help" element={<Help />} />
          </Route>

          {/* Global Catch-All Route */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Suspense>
    </Router>
  );
}
